import {
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  SELECT_ROLE_TO_EDIT,
} from '../types/role.type';

const initialState = {
  roles: [],
  total: 1,
  pages: 1,
  page: 1,
  roleToDelete: null,
  roleToEdit: null,
  error: null,
  loading: false,
};

export const role = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_REQUEST:
    case CREATE_ROLE_REQUEST:
    case UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        roleToDelete: action.roleId,
        error: null,
      };
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        roles: [...state.roles, action.role],
        total: state.total + 1,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        roles: action.roles,
        page: action.page,
        pages: action.pages,
        total: action.total,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        roles: state.roles.filter((role) => role._id !== state.roleToDelete),
        total: state.total - 1,
        roleToDelete: null,
      };
    case GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        roles: [],
      };
    case CREATE_ROLE_FAILURE:
    case DELETE_ROLE_FAILURE:
    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        roleToDelete: null,
        roleToEdit: null,
      };
    case SELECT_ROLE_TO_EDIT:
      return {
        ...state,
        roleToEdit: action.role,
      };
    default:
      return state;
  }
};
