export const hasPermission = (permission, permissions) => {
  return Boolean(permissions.find((p) => p === permission));
};

export const hasModule = (module, modules) => {
  return Boolean(modules.find((m) => m === module));
};

export const getAllSecurityInformation = (roles) => {
  let modules = [];
  let permissions = [];
  for (let i = 0; i < roles.length; i++) {
    let currents = roles[i].permissions;
    for (let j = 0; j < currents.length; j++) {
      permissions.push(currents[j].code);
      let exist = modules.find((m) => m === currents[j].parent);
      if (!exist) {
        modules.push(currents[j].parent);
      }
    }
  }
  return { modules: modules, permissions: permissions };
};
