import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {unAuthenticatedRoutes, authenticatedRoutes} from './routes';
import AuthenticatedLayout from './components/layouts/AuthenticatedLayout';
import applyDefaultInterceptors from './httpClient/applyDefaultInterceptors';
import httpClient from './httpClient';
import LanguageProvider from './provider/language.provider';
import local_storage from './constant/local_storage';
import defaultContry from './constant/defaultCountry';

const Preloader = React.lazy(() => import('./components/layouts/Preloader'));
const NoFound = React.lazy(() => import('./pages/NoFound'));

applyDefaultInterceptors(httpClient);
if (!localStorage.getItem(local_storage.COUNTRY_CODE)) {
  localStorage.setItem(local_storage.COUNTRY_CODE, defaultContry);
}
function App() {
  return (
    <LanguageProvider>
      <Router basename={'/'}>
        <Suspense fallback={<div></div>}>
          <Preloader />
          <Switch>
            {authenticatedRoutes.map((route, index) => (
              <Route
                key={index}
                exact
                path={route.path}
                render={(props) => (
                  <AuthenticatedLayout>
                    <route.component {...props} />
                  </AuthenticatedLayout>
                )}
              />
            ))}
           
            {unAuthenticatedRoutes.map((route, index) => (
              <Route
                key={index}
                exact
                path={route.path}
                component={route.component}
              />
            ))}
            <Route path="*">
              <NoFound />
            </Route>
          </Switch>
        </Suspense>
        <ToastContainer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
