import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAILURE,
  DELETE_PROMOTION_REQUEST,
  DELETE_PROMOTION_SUCCESS,
  DELETE_PROMOTION_FAILURE,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  UPDATE_PROMOTION_REQUEST,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
} from "../types/promotion.type";

const initialState = {
  promotions: [],
  total: 0,
  pages: 0,
  page: 1,
  promotionToDelete: null,
  promotionToEdit: null,
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const promotion = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMOTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        message: null,
      };
    case CREATE_PROMOTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROMOTION_REQUEST:
      return {
        ...state,
        loading: true,
        promotionToDelete: action.promotionId,
        error: null,
      };
    case CREATE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        promotions: [...state.promotions, action.promotion],
        total: state.total + 1,
      };
    case GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        message: null,
        promotions: action.promotions,
        page: action.page,
        pages: action.pages,
        total: action.total,
      };
    case DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        promotions: state.promotions.filter(
          (promotion) => promotion._id !== state.promotionToDelete
        ),
        total: state.total - 1,
        promotionToDelete: null,
        success: true,
        message: "deletedSuccess",
      };
    case GET_PROMOTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        promotions: [],
      };
    case CREATE_PROMOTION_FAILURE:
    case DELETE_PROMOTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null,
        message: "",
        promotionToDelete: null,
      };
    case UPDATE_PROMOTION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        message: null,
      };
    }
    case UPDATE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        message: "updatedSuccess",
      };
    case UPDATE_PROMOTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: "error",
      };
    default:
      return state;
  }
};
