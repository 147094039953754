export const CREATE_SINISTER_REQUEST = 'CREATE_SINISTER_REQUEST';
export const CREATE_SINISTER_SUCCESS = 'CREATE_SINISTER_SUCCESS';
export const CREATE_SINISTER_FAILURE = 'CREATE_SINISTER_FAILURE';

export const DELETE_SINISTER_REQUEST = 'DELETE_SINISTER_REQUEST';
export const DELETE_SINISTER_SUCCESS = 'DELETE_SINISTER_SUCCESS';
export const DELETE_SINISTER_FAILURE = 'DELETE_SINISTER_FAILURE';

export const GET_SINISTERS_REQUEST = 'GET_SINISTERS_REQUEST';
export const GET_SINISTERS_SUCCESS = 'GET_SINISTERS_SUCCESS';
export const GET_SINISTERS_FAILURE = 'GET_SINISTERS_FAILURE';
