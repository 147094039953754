import React from 'react';
import es_ES from '../language/es-ES';
import LanguageContext from '../context/language.context';

export default function LanguageProvider(props) {
  const locales = {
    en: {
      locale: 'en',
      messages: es_ES,
    },
    es: {
      locale: 'es',
      messages: es_ES,
    },
  };
  const getLocaleData = () => locales.es;
  return (
    <LanguageContext.Provider value={getLocaleData()}>
      {props.children}
    </LanguageContext.Provider>
  );
}
