import {lazy} from 'react';

const NoPermissions = lazy(() => import('../pages/NoPermissions'));
const Home = lazy(() => import('../pages/Home'));
const Campaign = lazy(() => import('../pages/Campaign'));
const Sinister = lazy(() => import('../pages/Sinister'));
const Configurations = lazy(() => import('../pages/Configurations'));
const Categories = lazy(() => import('../pages/Categories'));
const Services = lazy(() => import('../pages/Services'));
const Orders = lazy(() => import('../pages/Orders/Orders'));
const AddCampaign = lazy(() => import('../pages/AddCampaign'));
const CampaignDetails = lazy(() => import('../pages/CampaingDetails'));
const User = lazy(() => import('../pages/User/UserList'));
const AddUser = lazy(() => import('../pages/User/AddUser'));
const UserDetails = lazy(() => import('../pages/User/UserDetails'));
const Provider = lazy(() => import('../pages/Provider'));
const AddProvider = lazy(() => import('../pages/Provider/AddProvider'));
const AddSinister = lazy(() => import('../pages/Sinister/AddSinister'));
const ServiceDetails = lazy(() => import('../pages/Services/ServiceDetails'));
const ProviderDetails = lazy(() => import('../pages/Provider/ProviderDetails'));
const ProviderServices = lazy(() =>
  import('../pages/Provider/ProviderServices'),
);
const AsociateService = lazy(() => import('../pages/Provider/AsociateService'));
const SignIn = lazy(() => import('../pages/SignIn'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const Notification = lazy(() => import('../pages/Notification/List'));
const NotificationDetail = lazy(() => import('../pages/Notification/Details'));
const PromotionList = lazy(() => import('../pages/Contents/Promotions/List'));
const PromotionDetails = lazy(() =>
  import('../pages/Contents/Promotions/Details'),
);
const AddPromotion = lazy(() => import('../pages/Contents/Promotions/Add'));
const SliderList = lazy(() => import('../pages/Contents/Sliders/List'));
const AddSlider = lazy(() => import('../pages/Contents/Sliders/Add'));
const SliderDetails = lazy(() => import('../pages/Contents/Sliders/Details'));
const Roles = lazy(() => import('../pages/Roles'));
const Permissions = lazy(() => import('../pages/Permissions'));
const AddPermission = lazy(() => import('../pages/Permissions/AddPermission'));
const AddRole = lazy(() => import('../pages/Roles/AddRole'));
const EditRole = lazy(() => import('../pages/Roles/EditRole'));
const EditPromotion = lazy(() => import('../pages/Contents/Promotions/Edit'));

export const authenticatedRoutes = [
  {
    path: '/no-permissions',
    name: 'No Permissions',
    component: NoPermissions,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/campaign',
    name: 'Campañas',
    component: Campaign,
  },
  {
    path: '/sinisters',
    name: 'Siniestros',
    component: Sinister,
    permission: 'LIST_SINISTER',
  },
  {
    path: '/sinisters/add',
    name: 'Adicionar siniestros',
    component: AddSinister,
    permission: 'CREATE_SINISTER',
  },
  {
    path: '/users',
    name: 'Usuarios',
    component: User,
    permission: 'LIST_USER',
  },
  {
    path: '/users/add',
    name: 'Adicionar Usuario',
    component: AddUser,
    permission: 'CREATE_USER',
  },
  {
    path: '/users/detail/:id',
    name: 'Detalle del usuario',
    component: UserDetails,
  },
  {
    path: '/users/provider',
    name: 'Proveedores',
    component: Provider,
  },
  {
    path: '/content/promotion',
    name: 'Promotion',
    component: PromotionList,
  },
  {
    path: '/content/promotion-detail/:id',
    name: 'Promotion Details',
    component: PromotionDetails,
  },
  {
    path: '/content/promotion-edit/:id',
    name: 'Promotion Edit',
    component: EditPromotion,
  },
  {
    path: '/content/slider',
    name: 'Slider',
    component: SliderList,
  },
  {
    path: '/campaign/add-campaign',
    name: 'Add Campaign',
    component: AddCampaign,
  },
  {
    path: '/campaign/campaign-detail/:id',
    name: 'Campaign Detail',
    component: CampaignDetails,
  },
  {
    path: '/users/provider/add',
    name: 'Adicionar proveedor',
    component: AddProvider,
  },
  {
    path: '/users/provider/detail/:id',
    name: 'Detalle del proveedor',
    component: ProviderDetails,
  },
  {
    path: '/users/provider/services/:id',
    name: 'Servicios del proveedor',
    component: ProviderServices,
  },
  {
    path: '/users/provider/addServices/:id',
    name: 'Asociar servicio',
    component: AsociateService,
  },
  {
    path: '/configurations',
    name: 'Configurations',
    component: Configurations,
  },
  {
    path: '/categories',
    name: 'Categorías',
    component: Categories,
  },
  {
    path: '/orders',
    name: 'Ordenes',
    component: Orders,
  },
  {
    path: '/services',
    name: 'Servicios',
    component: Services,
  },
  {
    path: '/services/add',
    name: 'Add new service',
    component: () => <ServiceDetails addNewService={true} />,
  },
  {
    path: '/services/detail/:id',
    name: 'Service Detail',
    component: ServiceDetails,
  },
  {
    path: '/me/notifications',
    name: 'Notifications',
    component: Notification,
  },
  {
    path: '/notifications/detail/:id',
    name: 'Notification Details',
    component: NotificationDetail,
  },
  {
    path: '/content/add-promotion',
    name: 'Add Promotion',
    component: AddPromotion,
  },
  {
    path: '/content/add-slider',
    name: 'Add Slider',
    component: AddSlider,
  },
  {
    path: '/content/slider-detail/:id',
    name: 'Slider Details',
    component: SliderDetails,
  },
  {
    path: '/configurations/permissions',
    name: 'Permisos',
    component: Permissions,
  },
  {
    path: '/configurations/permissions/add',
    name: 'Adicionar Permisos',
    component: AddPermission,
  },
  {
    path: '/configurations/roles',
    name: 'Roles',
    component: Roles,
  },
  {
    path: '/configurations/roles/add',
    name: 'Adicionar Roles',
    component: AddRole,
  },
  {
    path: '/configurations/roles/edit',
    name: 'Editar Rol',
    component: EditRole,
  },
];

export const unAuthenticatedRoutes = [
  {
    path: '/auth/sign-in',
    name: 'Sign In',
    component: SignIn,
  },
  {
    path: '/auth/passwordReset',
    name: 'Password reset',
    component: ResetPassword,
  },
];
