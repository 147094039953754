const messages = {
  menu: {
    users: {
      title: 'Usuarios',
      operators: 'Operadores',
      providers: 'Proveedores',
    },
    coupons: {
      title: 'Cupones',
      campaigns: 'Campañas',
    },
    management: {
      title: 'Gestión',
      categories: 'Categorías',
      services: 'Servicios',
      configuration: 'Configuración',
    },
    services: {
      title: 'Solicitudes de servicios',
    },
    content: {
      title: 'Contenidos',
      sliders: 'Sliders',
      promotions: 'Promociones',
      files: 'Archivos',
    },
  },
  users: {
    all: 'Todos',
    gridTitle: 'Usuarios',
    profile: 'Perfil',
    users: 'Usuarios',
    newUser: 'Nuevo usuario',
    operators: 'Operadores',
    email: 'Email',
    name: 'Nombre',
    lastname: 'Apellido',
    phone: 'Teléfono móvil',
    roles: 'Roles',
    create: 'Crear usuario',
    rows: 'Filas por página',
    noResults: 'No hay resultados para mostrar',
    close: 'Cerrar',
    accept: 'Aceptar',
    add: 'Adicionar',
    select: 'Seleccionar',
    save: 'Guardar',
    cancel: 'Cancelar',
    noOptionsMessageRole: 'No hay mas roles por seleccionar',
    form: {
      placeholder: {
        name: 'Ingrese el nombre',
        lastName: 'Ingrese el apellido',
        email: 'Ingrese el email',
        phone: 'Ingrese el teléfono',
        newPassword: 'Ingrese nueva contraseña',
        password: 'Ingrese la contraseña',
        confirmPassword: 'Confirme la contraseña',
        confirmNewPassword: 'Confirme nueva contraseña',
      },
      error: {
        validEmail:
          'Por favor ingrese una dirección de correo electrónico válida.',
        wrongPassword: 'Las contraseñas no coinciden.',
      },
      requiredField: 'Campo requerido',
      title: 'Perfil',
      name: 'Nombre',
      lastName: 'Apellido',
      email: 'Email',
      phone: 'Teléfono móvil',
      roles: 'Roles',
      password: 'Contraseña',
      newPassword: 'Nueva contraseña',
      confirmNewPassword: 'Confirmación de contraseña',
    },
    messages: {
      wantToDeleteUser: '¿Desea eliminar el usuario?',
      deletedOperator: 'Usuario eliminado!',
      deleteOperator: 'Eliminar usuario',
    },
  },
  providers: {
    error: 'Error',
    title: 'Lista de proveedores',
    providerDetail: 'Detalle del proveedor',
    rut: 'RUT',
    users: 'Usuarios',
    providers: 'Proveedores',
    email: 'Email',
    fantasyName: 'Nombre de fantasía',
    socialReason: 'Razón social',
    firstName: 'Nombres',
    lastName: 'Apellidos',
    name: 'Nombre',
    phone: 'Teléfono móvil',
    document: 'Documento',
    roles: 'Roles',
    create: 'Crear proveedor',
    rows: 'Filas por página',
    noResults: 'No hay resultados para mostrar',
    wantToDeleteProvider: '¿Quieres eliminar el proveedor?',
    deletedProvider: 'Proveedor eliminado!',
    deleteProvider: 'Eliminar proveedor',
    close: 'Cerrar',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    newProvider: 'Nuevo Proveedor',
    addProvider: 'Agregar Proveedor',
    save: 'Guardar',
    toAsociateService: 'Asociar servicio',
    providerServices: 'Servicios del proveedor',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    deleteServices: 'Eliminar servicio',
    wantToDeleteService: '¿Quieres eliminar el servicio?',
    providerDeleted: 'El proveedor ha sido creado con éxito!',
    requiredField: 'Campo requerido *',
    selectCategoryPlaceholder: 'Seleccionar',
    selectServicePlaceholder: 'Seleccionar',
    zonesPlaceholder: 'Seleccionar',
    addCoverageArea: 'Adicionar zona de cobertura',
    additionalCost: 'Costo adicional',
    servicesCreatedOK: 'El servicio ha sido creado con exito',
    zone: 'Zona',
    zones: 'Zonas',
    service: 'Servicio',
    category: 'Categoría',
    asociateService: 'Asociar servicio',
    form: {
      companyInformation: 'Información de la empresa',
      providerInformation: 'Información del proveedor',
      rut: 'RUT',
      socialReason: 'Razón social',
      fantasyName: 'Nombre de fantasía',
      companyAddress: 'Dirección de la empresa',
      firstName: 'Nombres',
      lastName: 'Apellidos',
      email: 'Email',
      password: 'Contraseña',
      confirm: 'Confirmación',
      actived: 'Activo',
      avatar: 'Avatar',
      phone: 'Teléfono',
      document: 'Documento',
      country: 'País',
      state: 'Estado',
      city: 'Ciudad',
      address: 'Dirección particular',
      choseTheFile: 'Elige el archivo...',
      errors: {
        requiredText: 'Campo requerido',
        wrongEmail:
          'Por favor ingrese una dirección de correo electrónico válida',
        wrongPassword: 'Las contraseñas no coinciden',
      },
    },
  },
  configurations: {
    country: 'País',
    save: 'Guardar',
    basicConfigurations: 'Configuraciones básicas',
  },
  coupons: {
    createCampaign: 'Crear campaña',
    couponsPageTitle: 'Cupones',
    campaignListPageTitle: 'Campañas',
    campaignDetailPageTitle: 'Detalle de la campaña',
    rows: 'Filas por página',
    noResults: 'No hay resultados para mostrar',
    save: 'Guardar',
    close: 'Cerrar',
    accept: 'Aceptar',
    campaigns: 'Campañas',
    warning: 'Alerta',
    info: {
      campaignDeleted: 'Campaña eliminada',
      campaignAdded: 'Campaña adicionada',
    },
    campaignTable: {
      title: 'Título',
      description: 'Descripción',
      status: 'Estado',
      from: 'Desde',
      to: 'Hasta',
      prefix: 'Prefijo',
      suffix: 'Sufijo',
      action: 'Acción',
    },
    deleteCampaignText: '¿Desea eliminar la campaña?',
    percentage: 'Por ciento',
    amount: 'Cantidad',
    singleUse: 'Uso simple',
    multipleUse: 'Uso multiple',
    fullServices: 'Todos los servicios',
    singleCategory: 'Categoría',
    singleService: 'Servicio',
    userTable: {
      user: 'Usuario',
      assignedDate: 'Fecha de asignación',
      usedDate: 'Fecha de uso',
      redemptedDate: 'Fecha de redención',
    },
    couponTable: {
      code: 'Código',
    },
    couponGeneratedAlert: 'Los cupones se han generado satisfactoriamente',
    campaignBasicData: 'Datos básicos de la campaña',
    campaignExtraData: 'Datos extras de la campaña',
    campaignForm: {
      title: 'Título',
      titleError: 'Introduzca el título de la campaña',
      enabled: 'Habilitado',
      description: 'Descripción',
      descriptionError: 'Introduzca la descripción de la campaña',
      couponsType: 'Tipo de cupones',
      couponAmount: 'Cantidad de cupones',
      couponAmountError: 'El valor debe estar entre 1 y 1000',
      couponUsed: 'Cupones usados',
      usedQuantity: 'Cantidad de uso',
      usedQuantityError: 'El valor debe estar entre 1 y 1000',
      prefix: 'Prefijo',
      suffix: 'Sufijo',
      validFrom: 'Válido desde',
      validTo: 'Válido hasta',
      discountType: 'Tipo de descuento',
      discountTo: 'Descuento sobre',
      category: 'Categoría',
      categories: 'Categorías',
      selectCategory: '-- Seleccione la categoría --',
      service: 'Servicio',
      services: 'Servicios',
      selectService: '-- Seleccione el servicio --',
      currency: 'Moneda',
      discountValue: 'Valor del descuento',
      iconURL: 'URL del ícono',
      backgroundURL: 'URL de la imagen de fondo',
      errors: {
        requiredText: 'Campo requerido',
        discountPercentText: 'El valor debe estar entre 0 y 100',
        discountAmountText: 'El valor debe ser mayor o igual que 0',
      },
    },
    coupons: 'Cupones',
    users: 'Usuarios',
    generateCoupon: 'Generar cupon',
    generateCoupons: 'Generar cupones',
  },
  categories: {
    categories: 'Categorías',
    addCategories: 'Adicionar Categoría',
    deleteCategoryInfo:
      'Si elimina esta categoría, se eliminarán todas las subcategorías y servicios asociados a esta.',
    deleteCategoryText: '¿Desea realmente eliminar la categoría?',
    save: 'Guardar',
    close: 'Cerrar',
    cancel: 'Cancelar',
    attention: 'Atención',
    accept: 'Aceptar',
    parentCategory: 'Categoría padre',
    title: 'Título',
    description: 'Descripción',
    tags: 'Etiquetas',
    order: 'Orden',
    enabled: 'Habilitado',
    picture: 'Imagen',
    choseTheFile: 'Elige el archivo...',
    categoryDeleted: 'Categoría eliminada',
    categoryAdded: 'Categoría adicionada',
    categoryModified: 'Categoría modificada',
    addCategoryTitle: 'Adicionando categoría',
    editCategoryTitle: 'Editando categoría',
    categoryDetailTitle: 'Detalles de la categoría',
    message: {
      addCategory: 'Adicionando categoría',
      updateCategory: 'Modificando categoría',
    },
  },
  services: {
    services: 'Servicios',
    create: 'Agregar nuevo servicio',
    management: 'Gestión',
    wantToDeleteService: '¿Quieres eliminar el servicio?',
    deletedService: 'Servicio eliminado!',
    deleteService: 'Eliminar servicio',
    close: 'Cerrar',
    accept: 'Aceptar',
  },
  orders: {
    orders: 'Solicitudes de servicios',
    actives: 'Activas',
    all: 'Todas',
    services: 'Solicitudes',
  },
  contents: {
    promotion: {
      promotions: 'Listado de Promociones',
      table: {
        title: 'Título',
        description: 'Descripción',
        status: 'Estado',
        from: 'Desde',
        to: 'Hasta',
        action: 'Acción',
      },
      detail: {
        description: 'Descripción',
        status: 'Estado',
        from: 'Desde',
        to: 'Hasta',
        service: 'Servicio',
        category: 'Categoría',
        generic: 'Informativa',
        tag: 'Etiquetas',
        country: 'País',
        linkTo: 'Vinculo',
        fileName: 'Nombre imagen',
        fileLocation: 'Disponible en',
        fileCloud: 'En la nube',
        fileCloudOk: 'Si',
        fileCloudNot: 'No',
        enabled: 'Activada',
        disabled: 'Desactivada',
        goBack: 'Atrás',
        app: 'Movil',
        web: 'Web',
        tablet: 'Tablet',
      },
      new: 'Nueva Promoción',
      edit: 'Actualizando Promoción',
      form: {
        title: 'Título',
        titleError: 'Introduzca el título de la campaña',
        enabled: 'Habilitado',
        description: 'Descripción',
        promotionType: 'Tipo de Promoción',
        validFrom: 'Válido desde',
        validTo: 'Válido hasta',
        category: 'Categoría',
        generic: 'Informativa',
        categories: 'Categorías',
        subcategories: 'SubCategorias',
        selectCategory: '-- Seleccione la categoría --',
        selectSubCategory: '-- Seleccione la subcategoría --',
        service: 'Servicio',
        services: 'Servicios',
        selectService: '-- Seleccione el servicio --',
        file: 'Imagen',
        linkTo: 'Vínculo de Navegación',
        app: 'Movil',
        web: 'Web',
        tablet: 'Tablet',
        loadResource: 'Cargar imagen para resolucion',
      },
      create: 'Nueva',
      createdMessage: 'Nueva Promoción creada',
      deletePromotionText: '¿Desea eliminar la promoción?',
      deletedSuccess: 'Promocion eliminada',
      updatedSuccess: 'Promocion actualizada',
      close: 'Cerrar',
      accept: 'Aceptar',
      warning: 'Alerta',
      save: 'Guardar',
      goBack: 'Cancelar',
      error: 'Se ha producido un error inesperado',
    },
    slider: {
      sliders: 'Listado de Sliders',
      form: {
        title: 'Título',
        titleError: 'Introduzca el título de la campaña',
        enabled: 'Habilitado',
        description: 'Descripción',
        sliderType: 'Tipo de Slider',
        tag: 'Etiqueta',
        validFrom: 'Válido desde',
        validTo: 'Válido hasta',
        category: 'Categoría',
        categories: 'Categorías',
        selectCategory: '-- Seleccione la categoría --',
        service: 'Servicio',
        services: 'Servicios',
        selectService: '-- Seleccione el servicio --',
        file: 'Imagen',
        linkTo: 'Vínculo de Navegación',
      },
      table: {
        title: 'Título',
        description: 'Descripción',
        status: 'Estado',
        action: 'Acción',
      },
      detail: {
        title: 'Título',
        description: 'Descripción',
        status: 'Estado',
        tag: 'Etiquetas',
        enabled: 'Activada',
        disabled: 'Desactivada',
        from: 'Desde',
        to: 'Hasta',
        goBack: 'Atrás',
      },
      deleteSliderText: '¿Desea eliminar el Slider?',
      deletedSuccess: 'Slider eliminada',
      updatedSuccess: 'Slider actualizada',
      create: 'Nueva',
      createdMessage: 'Nueva Slider creada',
      close: 'Cerrar',
      accept: 'Aceptar',
      warning: 'Alerta',
      save: 'Guardar',
      error: 'Se ha producido un error inesperado',
    },
    file: {},
  },
  notification: {
    read: 'Leída',
    noRead: 'No leída',
    table: {
      title: 'Listado de notificaciones',
      from: 'De',
      message: 'Mensaje',
      status: 'Estado',
      creationDate: 'Fecha',
      actions: 'Acciones',
      deleteText: '¿Desea eliminar la notificación?',
      deleteTextError: 'Error eliminando la notificación',
      deleteTextSuccess: 'Notificación eliminada',
      markAsReadTextError: 'Error marcando la notificacion cómo leída',
      markAsReadTextSuccess: 'Notificación marcada como leída',
      tipMarkAsRead: 'Marcar como leída',
      tipDelete: 'Eliminar',
      detailsAccessError: 'Error visualizando los detalles',
      queryError: 'Error listando las notificaciones',
    },
    details: {
      title: 'Detalles notificación',
      from: 'De',
      message: 'Mensaje',
      creationDate: 'Fecha',
    },
    modal: {
      type: {
        warning: 'Advertencia',
      },
      accept: 'Aceptar',
      close: 'Cerrar',
    },
  },
  sinister: {
    sinisters: 'Siniestros',
    all: 'Todos',
    add: 'Adicionar',
    save: 'Guardar',
    close: 'Cancelar',
    accept: 'Aceptar',
    export: 'Exportar',
    exportPDF: 'Exportar PDF',
    addSinister: 'Adicionar siniestro',
    sinistersColumns: {
      taskNumber: 'Número de tarea',
      sinisterType: 'Tipo de siniestro',
      insuranceCompany: 'Aseguradora',
      ownerOrInsuredCompany: 'Titular o Empresa asegurada',
      declarePerson: 'Persona que declara',
      licensePlate: 'Matrícula',
      createdAt: 'Fecha de creación',
      fiscal: 'Fiscal',
      sanCristobalSinisterNumber: 'ID SC',
    },
    formError: {
      number: 'El número de tarea es requerido',
      sinisterType: 'El tipo de siniestro es requerido',
      insuranceCompany: 'La compañía aseguradora es requerida',
      claimSheetPhotos: 'Las fotos del parte son obligatorias.',
      insuranceCarPhotos: 'Las fotos del vehículo asegurado son obligatorias.',
    },
    sinisterType: {
      DOMICILIARIO: 'DOMICILIARIO',
      VEHICULAR: 'VEHICULAR',
      INSPECCION_PREVIA: 'INSPECCION PREVIA',
    },
    form: {
      select: 'Seleccione ...',
      basicData: 'Datos Básicos',
      taskNumber: 'Número de tarea',
      sanCristobalSinisterNumber: 'San Cristobal Id',
      sinisterType: 'Tipo de siniestro',
      insuranceCompany: 'Aseguradora',
      ownerOrInsuredCompany: ' Nombre del Titular o Empresa asegurada',
      declarePerson: ' Nombre de la persona que declara',
      licensePlate: 'Matrícula del auto',
      insuranceDocumentPhotos: 'FOTOS DOCUMENTOS ASEGURADO',
      thirdDocumentPhotos: 'FOTOS DOCUMENTOS TERCERO',
      claimSheetPhotos: 'FOTOS PARTE',
      insuranceCarPhotos: 'FOTOS VEHICULO ASEGURADO',
      thirdCarPhotos: 'FOTOS VEHICULO TERCERO',
      sketchPhotos: 'FOTOS CROQUIS',
      policeReportPhotos: 'FOTOS DENUNCIA POLICIAL',
    },
    loadActions: {
      uploadPhotos: 'Subiendo fotos',
      addSinister: 'Adicionando siniestro',
    },
    info: {
      wantToDelete: '¿Desea eliminar el registro de siniestro?',
      sinisterDeleted: 'Siniestro eliminado satisfactoriamente!',
      sinisterAdded: 'El siniestro ha sido adicionado satisfactoriamente!',
    },
    pdf: {
      sinisterPDFTitle: 'Siniestro-',
      title: 'Reporte de siniestro',
    },
  },
};

export default messages;
