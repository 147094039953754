import env from "./active.env";

const enviroments = {
  prod: {
    FENIX_API_URL: "https://fenix-api.servicios-ya.com/api",
    LEGACY_API_URL: "https://api.servicios-ya.com",
    AUTH: {
      GRANT_TYPE: "password",
      CLIENT_ID: "columnis_manager",
    },
    GOOGLE_MAPS_API_KEY: "AIzaSyBqR8ai9MGdY8o8OU2HJeT1aZNik2RlTZ8",
    ENABLED_SOCKET_NOTIFICATIONS: false,
  },
  staging: {
    FENIX_API_URL: "http://qa.fenix-api.servicios-ya.com:31160/api",
    LEGACY_API_URL: "http://ec2-34-204-203-87.compute-1.amazonaws.com:8080",
    AUTH: {
      GRANT_TYPE: "password",
      CLIENT_ID: "columnis_manager",
    },
    GOOGLE_MAPS_API_KEY: "AIzaSyBqR8ai9MGdY8o8OU2HJeT1aZNik2RlTZ8",
    ENABLED_SOCKET_NOTIFICATIONS: false,
  },
  dev: {
    FENIX_API_URL: "http://qa.fenix-api.servicios-ya.com:31160/api",
    LEGACY_API_URL: "http://ec2-34-204-203-87.compute-1.amazonaws.com:8080",
    AUTH: {
      GRANT_TYPE: "password",
      CLIENT_ID: "columnis_manager",
    },
    GOOGLE_MAPS_API_KEY: "AIzaSyBqR8ai9MGdY8o8OU2HJeT1aZNik2RlTZ8",
    ENABLED_SOCKET_NOTIFICATIONS: false,
  },
};

export const config = enviroments[env];
