import defaultContry from '../constant/defaultCountry';
import local_storage from '../constant/local_storage';
import {config as env} from '../config/env';
const AUTHORIZATION_HEADER = 'Authorization';
const UNAUTHORIZED = 401;

const applyDefaultInterceptors = (client) => {
  client.interceptors.request.use((config) => {
    const token = localStorage.getItem(local_storage.AUTH_TOKEN);
    const {headers} = config;
    if (token) {
      config.headers = {
        ...headers,
        [AUTHORIZATION_HEADER]: `Bearer ${token}`,
      };
    }

    const countryCode =
      localStorage.getItem(local_storage.COUNTRY_CODE) || defaultContry;

    if (!config.url.includes(env.LEGACY_API_URL)) {
      config.headers['countryCode'] = countryCode;
    }

    return config;
  });

  client.interceptors.response.use(
    async (response) => {
      return response.data;
    },
    (error) => {
      if (error.response && error.response.status === UNAUTHORIZED) {
        // TODO: User Logout
      }
      if (error?.response?.data) {
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error);
    },
  );
};

export default applyDefaultInterceptors;
