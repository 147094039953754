import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from '../types/user.type';

const initialState = {
  users: [],
  user: null,
  total: 1,
  pages: 1,
  page: 1,
  userToDelete: null,
  userToEdit: null,
  error: null,
  loading: false,
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
    case GET_USER_REQUEST:
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        userToDelete: action.userId,
        error: null,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        userToEdit: action.userId,
        error: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        users: [...state.users, action.user],
        total: state.total + 1,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.users,
        page: action.page,
        pages: action.pages,
        total: action.total,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.user,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: state.users.filter((user) => user._id !== state.userToDelete),
        total: state.total - 1,
        userToDelete: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: state.users.map((user) => {
          if (user._id === action.user._id) {
            return {
              ...user,
              ...action.user,
            };
          } else {
            return user;
          }
        }),
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: [],
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        user: null,
      };
    case CREATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        userToDelete: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        userToEdit: null,
      };
    default:
      return state;
  }
};
