import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { sinister } from "./sinister.reducer";
import { permission } from "./permission.reducer";
import { role } from "./role.reducer";
import { user } from "./user.reducer";
import { promotion } from "./promotion.reducer";

export const reducers = combineReducers({
  authentication,
  sinister,
  permission,
  role,
  user,
  promotion,
});
