export const CREATE_PERMISSION_REQUEST = 'CREATE_PERMISSION_REQUEST';
export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_FAILURE = 'CREATE_PERMISSION_FAILURE';

export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
