import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_USER,
} from "../types/authentication.type";

const initialToken = localStorage.getItem("token") || null;

const initialState = {
  user: null,
  permissions: [],
  modules: [],
  token: initialToken,
  error: null,
  loading: false,
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        token: null,
        error: action.error,
        permissions: [],
        modules: [],
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        permissions: [],
        modules: [],
        error: null
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
        permissions: action.payload.permissions,
        modules: action.payload.modules,
        error: null
      };
    default:
      return state;
  }
};
