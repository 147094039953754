import {
  CREATE_PERMISSION_REQUEST,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAILURE,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
} from '../types/permission.type';

const initialState = {
  permissions: [],
  total: 1,
  pages: 1,
  page: 1,
  permissionToDelete: null,
  permissionToEdit: null,
  error: null,
  loading: false,
};

export const permission = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_REQUEST:
    case CREATE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        permissionToDelete: action.permissionId,
        error: null,
      };
    case CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        permissions: [...state.permissions, action.permission],
        total: state.total + 1,
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        permissions: action.permissions,
        page: action.page,
        pages: action.pages,
        total: action.total,
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        permissions: state.permissions.filter(
          (permission) => permission._id !== state.permissionToDelete,
        ),
        total: state.total - 1,
        permissionToDelete: null,
      };
    case GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        permissions: [],
      };
    case CREATE_PERMISSION_FAILURE:
    case DELETE_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        permissionToDelete: null,
      };
    default:
      return state;
  }
};
