import {
  CREATE_SINISTER_REQUEST,
  CREATE_SINISTER_SUCCESS,
  CREATE_SINISTER_FAILURE,
  DELETE_SINISTER_REQUEST,
  DELETE_SINISTER_SUCCESS,
  DELETE_SINISTER_FAILURE,
  GET_SINISTERS_REQUEST,
  GET_SINISTERS_SUCCESS,
  GET_SINISTERS_FAILURE,
} from '../types/sinister.type';

const initialState = {
  sinisters: [],
  total: 1,
  pages: 1,
  page: 1,
  sinisterToDelete: null,
  sinisterToEdit: null,
  error: null,
  loading: false,
};

export const sinister = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINISTERS_REQUEST:
    case CREATE_SINISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SINISTER_REQUEST:
      return {
        ...state,
        loading: true,
        sinisterToDelete: action.sinisterId,
        error: null,
      };
    case CREATE_SINISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        sinisters: [...state.sinisters, action.sinister],
        total: state.total + 1,
      };
    case GET_SINISTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sinisters: action.sinisters,
        page: action.page,
        pages: action.pages,
        total: action.total,
      };
    case DELETE_SINISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sinisters: state.sinisters.filter(
          (sinister) => sinister._id !== state.sinisterToDelete,
        ),
        total: state.total - 1,
        sinisterToDelete: null,
      };
    case GET_SINISTERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        sinisters: [],
      };
    case CREATE_SINISTER_FAILURE:
    case DELETE_SINISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        sinisterToDelete: null,
      };
    default:
      return state;
  }
};
