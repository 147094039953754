import React, { useState, useEffect } from "react";
import Wrapper from "../Wrapper";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { authenticatedRoutes } from "../../routes";
import { hasPermission } from "../../security/utils";

const EXCLUDES_PATH = ["/", "/no-permissions"];

const AuthenticatedLayout = ({ children }) => {
  const auth = useSelector((state) => state.authentication);
  const [redirectTo, setRedirectTo] = useState();

  const getRouteByPath = (pathname) => {
    let path = pathname;
    if (path.slice(path.length - 1) == "/") {
      path = path.slice(0, -1);
    }
    const route = authenticatedRoutes.find((r) => r.path === path);
    return route;
  };

  const isExcludedUrl = (path) => {
    return Boolean(EXCLUDES_PATH.find((r) => r === path));
  };

  useEffect(() => {
/**const pathname = children.props.location.pathname;
    if (auth && !isExcludedUrl(pathname)) {
      const route = getRouteByPath(pathname);
      if (!hasPermission(route.permission, auth.permissions)) {
        setRedirectTo("/");
      }
    }*/
  }, []);

  if (!auth.token) {
    return <Redirect to={`/auth/sign-in`} />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return <Wrapper>{children}</Wrapper>;
};

export default AuthenticatedLayout;
